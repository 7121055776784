import {useAuth0} from '@auth0/auth0-react'
import Link from '@mui/material/Link'
import * as React from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import {getAssetCallbackUrl} from '~/utils/misc'

type Props = {
  assetType: 'dataroom' | 'grc'
  callbackType: 'login' | 'logout' | 'signup'
} & (
  | {
      queryKeyRedirect: string
      queryKeyCallback?: never
    }
  | {
      queryKeyRedirect?: never
      queryKeyCallback: string
    }
  | {
      queryKeyRedirect?: never
      queryKeyCallback?: never
    }
)

export default function Callback(props: Props) {
  const {
    assetType,
    callbackType,
    queryKeyCallback = 'callbackQuery',
    queryKeyRedirect = 'redirectQuery',
  } = props
  const {error} = useAuth0()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const assetCallbackUrl = getAssetCallbackUrl({
    assetType,
    queryKey: queryKeyCallback,
  })
  const redirectParam = decodeURIComponent(
    searchParams.get(queryKeyRedirect) ?? '',
  )

  const isGRCLoginError =
    assetType === 'grc' && callbackType === 'login' && !assetCallbackUrl
  const hasLoginError = error || isGRCLoginError
  const hasSignupError = searchParams.get('error')

  React.useEffect(() => {
    if (redirectParam) {
      navigate(redirectParam)
      return
    }

    if (assetCallbackUrl) {
      window.location.href = assetCallbackUrl
      return
    }
  }, [
    assetCallbackUrl,
    navigate,
    queryKeyRedirect,
    redirectParam,
    searchParams,
  ])

  if (callbackType === 'signup' && hasSignupError) {
    return (
      <MessageSection title="Signup">
        <p data-test-id="signup-message-error">
          We were unable to sign you up. Please try again
        </p>
      </MessageSection>
    )
  }

  if (callbackType === 'login' && hasLoginError) {
    return (
      <MessageSection title="Login">
        <p data-test-id="login-message-error">
          {`We were unable to log you in. Please try again or `}
          <Link href="https://www.ansarada.com/contact-support">
            contact us
          </Link>
          {'  if you still can not login.'}
        </p>
      </MessageSection>
    )
  }

  if (callbackType === 'logout' && !assetCallbackUrl) {
    return (
      <MessageSection
        title="Logged out"
        message={
          <span data-test-id="logout-message-info">
            You are now signed out.
          </span>
        }
      />
    )
  }

  return <MessageSection title={<Progress />} />
}
