import {type UseQueryOptions, useQuery} from '@tanstack/react-query'
import * as React from 'react'
import {matchPath, useLocation, useSearchParams} from 'react-router-dom'

import {useClient} from '~/context/auth-context'
import type {JobTitleGroupData, Location, LocationData, Option} from '~/types'

const useJobTitleGroups = ({
  defaultSpecialty,
  defaultTitle,
  options,
}: {
  defaultSpecialty: Option | null
  defaultTitle: Option | null
  options?: UseQueryOptions<JobTitleGroupData>
}) => {
  const client = useClient<JobTitleGroupData>()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  const assetType = searchParams.get('assetType')
  const isEditProfilePage = !!matchPath(location.pathname, '/profile')
  const endpointV2 = 'reference-data-api/v2/jobtitlegroups'
  const endpointV1 = 'reference-data-api/v1/jobtitlegroups'
  const apiEndpoint = isEditProfilePage
    ? endpointV2
    : assetType
      ? endpointV2.concat(`?assetType=${assetType}`)
      : endpointV1

  const {data: jobTitleGroupData, isLoading} = useQuery({
    queryKey: ['job-title-groups'],
    queryFn: () => client(apiEndpoint).then(data => data),
    ...options,
  })

  const jobTitleGroups = React.useMemo(
    () => (jobTitleGroupData ? jobTitleGroupData.jobTitleGroups : []),
    [jobTitleGroupData],
  )

  const [selectedSpecialty, setSelectedSpecialty] =
    React.useState<Option | null>(defaultSpecialty)
  const [selectedTitle, setSelectedTitle] = React.useState<Option | null>(
    defaultTitle,
  )

  const specialties: Option[] = React.useMemo(
    () =>
      jobTitleGroups.map(group => ({
        label: group.jobTitleGroupName,
        value: group.jobTitleGroupName,
      })),
    [jobTitleGroups],
  )

  const selectedJobTitleGroup = jobTitleGroups.find(
    x => x.jobTitleGroupName === selectedSpecialty?.value,
  )

  const jobTitles = React.useMemo(
    () =>
      selectedJobTitleGroup
        ? selectedJobTitleGroup.jobTitles.map(jobTitle => ({
            label: `${jobTitle.jobTitleName}`,
            value: String(jobTitle.jobTitleId),
          }))
        : [],
    [selectedJobTitleGroup],
  )

  const isSelectedOtherJobTitle = selectedSpecialty
    ? selectedSpecialty.value === 'Other'
    : false

  return {
    specialties,
    selectedSpecialty,
    setSelectedSpecialty,
    jobTitles,
    selectedTitle,
    isSelectedOtherJobTitle,
    setSelectedTitle,
    isLoading,
  }
}

const useSearchLocation = ({
  defaultLocation,
  options,
}: {
  defaultLocation: Location | null
  options?: UseQueryOptions<Location[]>
}) => {
  const client = useClient<LocationData>()
  const [selectedLocation, setSelectedLocation] =
    React.useState(defaultLocation)
  const [queryValue, setQueryValue] = React.useState('')
  const {data: locations} = useQuery({
    queryKey: ['location-name', queryValue],
    queryFn: () =>
      client(`reference-data-api/v1/locations?search=${queryValue}`).then(
        data => data.locations,
      ),
    enabled: !!queryValue,
    ...options,
  })

  const isLoading = !!queryValue && !locations

  return {
    locations: locations ?? [],
    selectedLocation,
    setSelectedLocation,
    isLoading,
    queryValue,
    setQueryValue,
  }
}

export {useJobTitleGroups, useSearchLocation}
