import type {AppConfig} from '~/types'

const config: AppConfig = {
  gatewayBaseUrl: process.env.GATEWAY_BASE_URL,
  platformHomeUrl: process.env.PLATFORM_HOME_URL,
  dealsUrl: process.env.DEALS_URL,
  freeTrialUrl: process.env.FREE_TRIAL_URL,
  ansaradadotcomBaseUrl: process.env.ANSARADADOTCOM_BASE_URL,
  identityUrl: process.env.IDENTITY_BASE_URL,

  auth: {
    domain: process.env.AUTH_DOMAIN,
    clientId: process.env.AUTH_CLIENT_ID,
    redirectUri: process.env.AUTH_REDIRECT_URI,
    logoutRedirectUri: process.env.AUTH_LOGOUT_REDIRECT_URI,
    socialSignupClientId: process.env.AUTH_SOCIAL_SIGNUP_CLIENT_ID,
    signupRedirectUri: process.env.AUTH_SIGNUP_REDIRECT_URI,
    freeTrialRedirectUri: process.env.AUTH_FREE_TRIAL_REDIRECT_URI,
    audience: 'https://api.ansarada.com/identity/',
    scope: 'openid profile email',
  },

  newRelic: {
    licenseKey: process.env.NEW_RELIC_LICENSE_KEY,
    appId: process.env.NEW_RELIC_APPLICATION_ID,
    accountId: process.env.NEW_RELIC_ACCOUNT_ID,
    trustKey: process.env.NEW_RELIC_TRUST_KEY,
    agentId: process.env.NEW_RELIC_AGENT_ID,
    allowedOrigins: process.env.NEW_RELIC_ALLOWED_ORIGINS,
  },

  segmentApiKey: process.env.SEGMENT_API_KEY,
}

export default config
