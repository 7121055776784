import {type InferType, boolean, number, object, string} from 'yup'

const defaultValidationSchema = object().shape({
  firstName: string().trim().required('First name is required'),
  lastName: string().trim().required('Last name is required'),
  phoneNumber: string().trim().required('Mobile is required'),
  password: string().when(['isSso', 'hasExistingPassword'], {
    is: (isSso: boolean, hasExistingPassword: boolean) =>
      !isSso && !hasExistingPassword,
    then: fieldSchema => fieldSchema.required('Password is required'),
  }),
  jobTitleGroupName: string().when('jobTitleId', {
    is: (jobTitleId: number | null) => !jobTitleId,
    then: string().required('Organisation type is required'),
  }),
  jobTitleId: number()
    .nullable()
    .notOneOf([0, null, undefined], 'Title is required'),
  locationId: number().required('City is required'),
  termsAndConditionsAccepted: boolean().oneOf([true]),
})

type ValidationSchemaKeys = keyof InferType<typeof defaultValidationSchema>

const buildValidationSchema = (requiredKeys: ValidationSchemaKeys[]) => {
  return defaultValidationSchema.pick(requiredKeys)
}

export {buildValidationSchema}
